import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import { toast } from "react-toastify";

import Alert from "../components/Alert";
import React from "react";
import Select from "../components/Select";

import { useQueryAgencies } from "../api/agencies";
import { useCreateHomeMessage } from "../api/homeMessages";
import Checkbox from "../components/Checkbox";
import {WysiwygTextEditor} from "../components/WysiwygTextEditor";
import { useDesktop } from "../hooks/useDesktop";


const validation = Yup.object().shape({
  message: Yup.string().required("Requis"),
  agencies: Yup.array().nullable(),
});

export default function DashboardAdminForm(readOnly) {
  const [createHomeMessage, { error }] = useCreateHomeMessage();
  const { data: agencies } = useQueryAgencies();
  const isDesktop = useDesktop();

  return (
    <div>
      <Formik
        initialValues={{
          message: "",
          agencies: []
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            values.agencies = values.agencies.map((agency) => agency.value);
            await createHomeMessage({
              ...values,
            });
            toast.success("Message créé avec succès");
            actions.resetForm();

          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
            window.location.reload();
          }
        }}
      >
        {({ values,setFieldValue, isSubmitting, submitCount, errors }) => {
          return (
            <Form className="mb-12">
              
              <WysiwygTextEditor
                  name="message"
                  label="Rédiger un nouveau message"
                  toolbarMini={!isDesktop}
                  withColorPen={false}
                  setFieldValue={(val) => setFieldValue("message", val.replace(/<\/?p(.)*?>|<\/?span(.)*?>/g,""))}
              />

              <div className="mt-8">
                <Select
                  label="Site(s) concerné(s)"
                  isMulti
                  name="agencies"
                  options={map(agencies, (node) => ({
                    label: node.label,
                    value: node["@id"],
                  }))}
                  value={values.agencies}
                  isDisabled={readOnly}
                  isLoading={!agencies}
                />
              </div>
              <Checkbox
                  readOnly={readOnly}
                  type="checkbox"
                  name="prevention"
                  label="Prévention"
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              {submitCount && !isSubmitting && !errors && !error ? (
                <Alert
                  className="mt-8"
                  type="success"
                  message="Votre demande a bien été envoyée"
                  canBeHidden
                />
              ) : null}
              <div className="text-center mt-3">
                <button className={`btn`} type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="loader h-8 w-8 mx-auto"></div>
                  ) : (
                    "Envoyer"
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
