import { useQuery } from "react-query";
import queryWithToken, { findExport } from "./index";

import { get } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "evaluation_histories";
//

export function useQueryEvaluationHistory(filters = {}) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export async function downloadEvaluationHistoryExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_rapport_evaluation.csv");
}
