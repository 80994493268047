import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";
import { map, size } from "lodash-es";
import { useLockBodyScroll, useToggle } from "react-use";
import { ReactComponent as ArrowRightAltIcon } from "../svgs/arrow-right-alt.svg";
import { ReactComponent as ArrowRightIcon } from "../svgs/arrow-right.svg";
import {
  useIsModuleActive,
  useLokoaUrl,
  useMyLinkUrl,
  usePermissionsChecker,
} from "../contexts/permissions";
import ReactTooltip from "react-tooltip";
import { useDesktop } from "../hooks/useDesktop";
import ExternalLink from "./ExternalLink";

const FakeLink = ({ children, exact, ...props }) => {
  return <div {...props}>{children}</div>;
};

function MenuLink({
  id,
  label,
  hasChildren,
  icon: Icon,
  isDesktop,
  level,
  isOpen,
}) {
  return (
    <div
      className="Menu-link text-sm hover:bg-gray-600 hover:text-green-600 text-white text-lg py-4 px-6 flex items-center justify-center cursor-pointer"
      data-tip
      data-for={`tooltip_menu_${id}`}
    >
      {Icon ? (
        <div className="Menu-icon flex items-center justify-center">
          <Icon className="text-white fill-current h-8 w-8" />
        </div>
      ) : null}
      <div className="Menu-label flex-1 ml-3">{label}</div>
      {isDesktop && level === 0 && !isOpen ? (
        <ReactTooltip id={`tooltip_menu_${id}`} place="right" effect="solid">
          {label}
        </ReactTooltip>
      ) : null}
      <div className="Menu-arrow">
        {hasChildren ? (
          <ArrowRightIcon className="fill-current text-white h-3 w-2 opacity-50" />
        ) : (
          <ArrowRightAltIcon className="fill-current text-white h-3 w-3 opacity-50" />
        )}
      </div>
    </div>
  );
}

function MenuItem({ item, toggleMenu, level = 0, isOpen }) {
  const isDesktop = useDesktop();
  const [subMenuIsOpen, toggle] = useToggle(false);
  const myLinkUrl = useMyLinkUrl();
  const lokoaUrl = useLokoaUrl();

  const path =
    typeof item.path === "function"
      ? item.path({ myLinkUrl, lokoaUrl })
      : item.path;

  useEffect(() => {
    toggle(false);
  }, [toggle]);

  useEffect(() => {
    // close all sub level
    if (!isOpen) {
      toggle(false);
    }
  }, [isOpen, toggle]);

  let Component = null;
  const componentsProps = {};
  if (item.external) {
    Component = ExternalLink;
  } else if (path && size(item.children) <= 0) {
    Component = NavLink;
    componentsProps.exact = true;
  } else {
    Component = FakeLink;
  }

  return (
    <Component
      to={path}
      className="Menu-link-container"
      {...componentsProps}
      onClick={(e) => {
        if (size(item.children) > 0) {
          e.preventDefault();
          if (level === 0 && isDesktop && !isOpen) {
            toggleMenu(true);
          } else {
            toggle(true);
          }
        } else if (path) {
          if (toggleMenu && !isDesktop) {
            toggleMenu(false);
          }
        }
      }}
    >
      <MenuLink
        id={item.id}
        icon={item.icon}
        label={item.label}
        hasChildren={size(item.children)}
        isDesktop={isDesktop}
        isOpen={isOpen}
        level={level}
      />
      {size(item.children) > 0 ? (
        <div
          className={`Menu-subMenu absolute w-full h-full z-40 bg-gray-500 top-0 left-0 ${
            subMenuIsOpen ? "Menu-subMenu--open" : ""
          }`}
        >
          <div
            className="py-4 px-8 flex items-center hover:text-green-600 text-white cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.54"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.40002 10.6L6.00002 12L2.48432e-05 6L6.00003 -1.22392e-07L7.40003 1.4L2.80003 6L7.40002 10.6Z"
                fill="white"
              />
            </svg>
            <div className="ml-4 text-sm">{item.label}</div>
          </div>
          <MenuTree
            menu={item.children}
            toggleMenu={toggleMenu}
            isOpen={isOpen}
            level={level + 1}
          />
        </div>
      ) : null}
    </Component>
  );
}

function MenuTree({ menu, toggleMenu, level, isOpen }) {
  return map(menu, (item) => {
    return (
      <PermissionAndModuleChecker
        key={item.id}
        permissions={item.permissions || []}
        module={item.module}
      >
        <MenuItem
          item={item}
          toggleMenu={toggleMenu}
          isOpen={isOpen}
          level={level}
        />
      </PermissionAndModuleChecker>
    );
  });
}

function PermissionAndModuleChecker({ module, permissions, children }) {
  const isModuleOk = useIsModuleActive(module);
  const isPermissionOk = usePermissionsChecker({
    permissions: permissions || [],
  });
  if (module ? isModuleOk : isPermissionOk) {
    return children;
  }
  return null;
}

function Menu({ isOpen, toggleMenu, tree }) {
  const isDesktop = useDesktop();
  useLockBodyScroll(!isDesktop && isOpen);

  return (
    <div
      className={`${isDesktop || isOpen ? "Menu--open" : ""} ${
        isOpen ? "w-sidebar-open overflow-auto" : "w-sidebar overflow-hidden"
      }  Menu font-title absolute left-0 top-full bg-gray-500 flex flex-col h-fullWithoutMenuHeight w-full`}
    >
      <MenuTree menu={tree} isOpen={isOpen} toggleMenu={toggleMenu} level={0} />
    </div>
  );
}

export default Menu;
