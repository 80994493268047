import { ReactComponent as DocsEntrepriseIcon } from "./svgs/docs-entreprise.svg";
import { ReactComponent as HomeIcon } from "./svgs/home.svg";
import { ReactComponent as ParamsIcon } from "./svgs/parametrage.svg";
import { ReactComponent as RHIcon } from "./svgs/ressources-humaines.svg";
import { ReactComponent as StatsIcon } from "./svgs/stats.svg";
import { ReactComponent as CloudDdlIcon } from "./svgs/cloudDdl.svg";
import { ReactComponent as VehicleIcon } from "./svgs/car.svg";
import { ReactComponent as PreventionIcon } from "./svgs/prevention.svg";
import { ReactComponent as EditIcon } from "./svgs/edit.svg";
import { ReactComponent as ProcessIcon } from "./svgs/process.svg";
import { ReactComponent as CongesIcon } from "./svgs/conges.svg";
import { ReactComponent as CalendrierIcon } from "./svgs/calendrier.svg";
import { ReactComponent as LinksIcon } from "./svgs/link-internet.svg";
import { ReactComponent as FormationIcon } from "./svgs/formation.svg";
import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    label: "Tableau de bord",
    path: "/",
    icon: HomeIcon,
    permissions: [
      "kdix.actions.collaborator.view",
      "kdix.actions.collaborator.view.agency",
      "kdix.actions.collaborator.view.department",
      "kdix.actions.collaborator.view.service",
      "kdix.actions.collaborator.view.own",
    ],
  },
  {
    id: uuid(),
    label: "Mot du dirigeant",
    icon: EditIcon,
    path: "/parametres/mot-dirigeant",
    permissions: [
      "kdix.actions.home_message.create",
      "kdix.actions.home_message.history",
    ],
  },
  {
    id: uuid(),
    label: "Les collaborateurs",
    icon: RHIcon,
    path: "/rh/collaborateurs",
    permissions: [
      "kdix.actions.collaborator.view",
      "kdix.actions.collaborator.view.agency",
      "kdix.actions.collaborator.view.department",
      "kdix.actions.collaborator.view.service",
      "kdix.actions.collaborator.view.own",
    ],
  },
  {
    id: uuid(),
    label: "Compét./Format./Habilit.",
    path: null,
    icon: FormationIcon,
    permissions: [
      "kdix.actions.collaborator.view",
      "kdix.actions.collaborator.view.agency",
      "kdix.actions.collaborator.view.department",
      "kdix.actions.collaborator.view.service",
      "kdix.actions.collaborator.view.own",
    ],
    children: [
      {
        id: uuid(),
        label: "Formations",
        path: "/rh/formations/catalogue",
        module: "formation",
      },
      {
        id: uuid(),
        label: "Habilitations",
        module: "accreditation",
        children: [
          {
            id: uuid(),
            label: "Catalogue",
            path: "/rh/habilitations/catalogue",
            module: "accreditation",
          },
          {
            id: uuid(),
            label: "Habilités",
            path: "/rh/habilitations/habilites",
            permissions: [
              "kdix.actions.accreditation.manage",
              "kdix.actions.accreditation.manage.agency",
              "kdix.actions.accreditation.manage.department",
              "kdix.actions.accreditation.manage.service",
              "kdix.actions.accreditation.manage.own",
            ],
          },
        ],
      },
      {
        id: uuid(),
        label: "Compétences / Évaluations",
        permissions: ["kdix.actions.skill_evaluation.edit_create"],
        children: [
          {
            id: uuid(),
            label: "Référentiel compétences",
            path: "/rh/competences",
            module: "skill_evaluation",
          },
          {
            id: uuid(),
            label: "Référentiel postes",
            path: "/rh/fiches_poste",
            module: "skill_evaluation",
          },
          {
            id: uuid(),
            label: "Évaluateurs",
            path: "/rh/competence/evaluateurs",
            permissions: ["kdix.actions.skill_evaluation.edit_evaluator"],
          },
          {
            id: uuid(),
            label: "Cartographie des Compétences",
            path: "/rh/competences/cartographies",
            module: "skill_evaluation",
          },
          {
            id: uuid(),
            label: "Suivi des évaluations",
            path: "/rh/competences/suivi_campagnes",
            permissions: ["kdix.actions.skill_evaluation.edit_campaign",
            "kdix.actions.skill_evaluation.viewall_campaign"],
          },
        ],
      },
    ],
  },
  {
    id: uuid(),
    label: "Saisie des temps",
    icon: CalendrierIcon,
    path: ({ lokoaUrl }) => {
      return lokoaUrl ?? "https://www.k-dix.fr/";
    },
    external: true,
    permissions: ["kdix.actions.lokoa.view"],
  },
  {
    id: uuid(),
    label: "Les absences",
    icon: CongesIcon,
    permissions: [
      "kdix.actions.absence.view",
      "kdix.actions.absence.view.agency",
      "kdix.actions.absence.view.department",
      "kdix.actions.absence.view.service",
    ],
    children: [
      {
        id: uuid(),
        label: "Validation des absences",
        path: "/validation-absences",
        permissions: [
          "kdix.actions.absence.edit",
          "kdix.actions.absence.edit.agency",
          "kdix.actions.absence.edit.department",
          "kdix.actions.absence.edit.service",
          "kdix.actions.absence.edit.own",
        ],
      },
      {
        id: uuid(),
        label: "Planning des absences",
        path: "/planning-absences",
        permissions: [
          "kdix.actions.absence.view",
          "kdix.actions.absence.view.agency",
          "kdix.actions.absence.view.department",
          "kdix.actions.absence.view.service",
        ],
      },
      {
        id: uuid(),
        label: "Absents du jour",
        path: "/collaborators-absents",
        permissions: [
          "kdix.actions.absence.view",
          "kdix.actions.absence.view.agency",
          "kdix.actions.absence.view.department",
          "kdix.actions.absence.view.service",
        ],
      },
    ],
  },
  {
    id: uuid(),
    label: "Véhicules",
    icon: VehicleIcon,
    path: "/rh/vehicules",
    permissions: [
      "kdix.actions.vehicle.view",
      "kdix.actions.vehicle.view.agency",
      "kdix.actions.vehicle.view.department",
      "kdix.actions.vehicle.view.service",
      "kdix.actions.vehicle.view.own",
    ],
  },
  {
    id: uuid(),
    label: "Prévention",
    icon: PreventionIcon,
    permissions: [
      "kdix.actions.work_accident.view",
      "kdix.actions.work_accident.view.agency",
      "kdix.actions.work_accident.view.department",
      "kdix.actions.work_accident.view.service",
      "kdix.actions.work_accident.view.own",
      "kdix.actions.request_prevention.view",
      "kdix.actions.request_prevention.view.agency",
      "kdix.actions.request_prevention.view.department",
      "kdix.actions.request_prevention.view.service",
      "kdix.actions.request_prevention.view.own",
    ],
    children: [
      {
        id: uuid(),
        label: "Accidents Du Travail",
        path: "/prevention/accidents-travail",
        permissions: [
          "kdix.actions.work_accident.view",
          "kdix.actions.work_accident.view.agency",
          "kdix.actions.work_accident.view.department",
          "kdix.actions.work_accident.view.service",
          "kdix.actions.work_accident.view.own",
        ],
      },
      {
        id: uuid(),
        label: "Accidents Requalifiés",
        path: "/prevention/accidents-requalifies",
        permissions: [
          "kdix.actions.work_accident.view",
          "kdix.actions.work_accident.view.agency",
          "kdix.actions.work_accident.view.department",
          "kdix.actions.work_accident.view.service",
          "kdix.actions.work_accident.view.own",
        ],
      },
      {
        id: uuid(),
        label: "Messages de prévention",
        path: "/prevention/messages-prevention",
        permissions: [
          "kdix.actions.request_prevention.view",
          "kdix.actions.request_prevention.view.agency",
          "kdix.actions.request_prevention.view.department",
          "kdix.actions.request_prevention.view.service",
          "kdix.actions.request_prevention.view.own",
        ],
      },
      {
        id: uuid(),
        label: "Messages prévention chantiers",
        path: "/prevention/messages-preventionchantiers",
        module: "lokoa_projects",
        permissions: [
          "kdix.actions.request_prevention.view",
          "kdix.actions.request_prevention.view.agency",
          "kdix.actions.request_prevention.view.department",
          "kdix.actions.request_prevention.view.service",
          "kdix.actions.request_prevention.view.own",
        ],
      },
      {
        id: uuid(),
        label: "Demandes de prévention",
        path: "/prevention/demandes-prevention",
        permissions: [
          "kdix.actions.request_prevention.view",
          "kdix.actions.request_prevention.view.agency",
          "kdix.actions.request_prevention.view.department",
          "kdix.actions.request_prevention.view.service",
          "kdix.actions.request_prevention.view.own",
        ],
      },
      {
        id: uuid(),
        label: "Messages du dirigeant",
        path: "/prevention/messages-dirigeant",
        permissions: [
          "kdix.actions.message.view",
          "kdix.actions.message.view.agency",
          "kdix.actions.message.view.department",
          "kdix.actions.message.view.service",
          "kdix.actions.message.view.own",
        ],
      },
    ],
  },
  {
    id: uuid(),
    label: "Statistiques",
    icon: StatsIcon,
    path: "/statistiques",
    permissions: ["kdix.actions.statistics.view"],
  },
  {
    id: uuid(),
    label: "Exports",
    icon: CloudDdlIcon,
    path: "/parametres/exports",
    permissions: [
      "kdix.actions.collaborator.export",
      "kdix.actions.collaborator.export.agency",
      "kdix.actions.collaborator.export.department",
      "kdix.actions.collaborator.export.service",
      "kdix.actions.collaborator.export.own",
      "kdix.actions.leave_days.export",
      "kdix.actions.leave_days.export.agency",
      "kdix.actions.leave_days.export.department",
      "kdix.actions.leave_days.export.service",
      "kdix.actions.vehicle.edit",
      "kdix.actions.vehicle.edit.agency",
      "kdix.actions.vehicle.edit.department",
      "kdix.actions.vehicle.edit.service",
    ],
  },
  {
    id: uuid(),
    label: "Suivi des processus",
    icon: ProcessIcon,
    path: "/processus/liste",
    permissions: [
      "kdix.actions.process_in_out.view",
      "kdix.actions.process_in_out.view.agency",
      "kdix.actions.process_in_out.view.department",
      "kdix.actions.process_in_out.view.service",
    ],
  },
  {
    id: uuid(),
    label: "Docs entreprise",
    icon: DocsEntrepriseIcon,
    path: "/documents-entreprise",
    permissions: [
      "kdix.actions.documents_link.view",
      "kdix.actions.documents_link.view_all",
    ],
  },
  {
    id: uuid(),
    label: "Mes liens",
    icon: LinksIcon,
    path: "/liens",
    permissions: ["kdix.actions.my_links.view"],
  },
  {
    id: uuid(),
    label: "Paramètres",
    icon: ParamsIcon,
    path: "/parametres",
    permissions: [
      "kdix.actions.alert_badge.edit",
      "kdix.actions.agency.edit",
      "kdix.actions.user.manage",
      "kdix.actions.process_in_out.edit",
      "kdix.actions.process_in_out.edit.agency",
      "kdix.actions.process_in_out.edit.department",
      "kdix.actions.process_in_out.edit.service",
      "kdix.actions.validators.view",
    ],
    children: [
      {
        id: uuid(),
        label: "Collaborateurs",
        permissions: [
          "kdix.actions.alert_badge.edit",
        ],
        children: [
          {
            id: uuid(),
            label: "Badge",
            path: "/parametres/badges",
            permissions: ["kdix.actions.alert_badge.edit"],
          },
          {
            id: uuid(),
            label: "Contrat",
            permissions: ["kdix.actions.alert_badge.edit"],
            children: [
              {
                id: uuid(),
                label: "Types de Contrats",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-contrats",
              },
              {
                id: uuid(),
                label: "Types de Catégories",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-contrat-categorie",
              },
              {
                id: uuid(),
                label: "Statuts de Contrats",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/statuts-contrat",
              },
              {
                id: uuid(),
                label: "Types de rémunérations salariales",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-methodes-calcul",
              },
              {
                id: uuid(),
                label: "Types d'évolutions salariales",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-raisons",
              },
              {
                id: uuid(),
                label: "Types de durées hebdomadaires",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-duree-hebdomadaire",
              },
              {
                id: uuid(),
                label: "Types de primes",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-primes",
              },
              {
                id: uuid(),
                label: "Types de fins de contrats",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-process",
              },
            ],
          },
        ],
      },
      {
        id: uuid(),
        label: "Déploiement",
        children: [
          {
            id: uuid(),
            label: "Domaines",
            path: "/parametres/deploiement/domains",
          },
          {
            id: uuid(),
            label: "Silae",
            path: "/parametres/deploiement/silae",
          },
        ],
      },
      {
        id: uuid(),
        label: "Entreprise",
        permissions: ["kdix.actions.agency.edit",
                      "kdix.actions.validators.view",
                      "kdix.actions.alert_badge.edit",],
        children: [
          {
            id: uuid(),
            label: "Sociétés",
            path: "/parametres/societes",
            permissions: ["kdix.actions.agency.edit"],
          },
          {
            id: uuid(),
            label: "Sites",
            path: "/parametres/agences",
            permissions: ["kdix.actions.agency.edit"],
          },
          {
            id: uuid(),
            label: "Communication",
            permissions: [
              "kdix.actions.alert_badge.edit",
            ],
            children: [
              {
                id: uuid(),
                label: "Types d'Alertes",
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/alertes",
              },
              {
                id: uuid(),
                label: 'Types de Demandes "Vous avez la parole"',
                permissions: ["kdix.actions.alert_badge.edit"],
                path: "/parametres/types-demandes",
              },
              {
                id: uuid(),
                label: "Types de Messages",
                path: "/parametres/types-message",
                permissions: ["kdix.actions.alert_badge.edit"],
              },
            ],
          },
          {
            id: uuid(),
            label: "Services",
            path: "/parametres/code-service",
            permissions: ["kdix.actions.agency.edit"],
          },
          {
            id: uuid(),
            label: "Départements",
            path: "/parametres/departements",
            permissions: ["kdix.actions.agency.edit"],
          },
          {
            id: uuid(),
            label: "Emplois",
            path: "/parametres/emplois",
            permissions: ["kdix.actions.alert_badge.edit"],
          },
          {
            id: uuid(),
            label: "Liens internet",
            path: "/parametres/liens",
            permissions: ["kdix.actions.alert_badge.edit"],
          },
          {
            id: uuid(),
            label: "Absences",
            permissions: [
              "kdix.actions.user.manage", // gerer les profils et les droits
              "kdix.actions.alert_badge.edit", // modification des parametres
              "kdix.actions.validators.view", // voir les infos complementaires de tous
            ],
            children: [
              {
                id: uuid(),
                label: "Types d'Absences",
                path: "/parametres/types-absences",
                permissions: ["kdix.actions.alert_badge.edit"],
              },
              {
                id: uuid(),
                label: "Valideurs",
                path: "/parametres/valideurs",
                permissions: ["kdix.actions.user.manage",
                "kdix.actions.validators.view"],
              },
              {
                id: uuid(),
                label: "Pré-valideurs",
                permissions: ["kdix.actions.user.manage",
                "kdix.actions.validators.view"],
                path: "/parametres/pre-valideurs",
              },
            ],
          },
        ],
      },
      {
        id: uuid(),
        label: "Gestion des utilisateurs",
        permissions: ["kdix.actions.user.manage"],
        children: [
          {
            id: uuid(),
            label: "Gestion des profils",
            path: "/parametres/profil",
            permissions: ["kdix.actions.user.manage"],
          },
          {
            id: uuid(),
            label: "Gestion des modules",
            path: "/parametres/modules",
          },
          {
            id: uuid(),
            label: "Gestion des droits",
            path: "/parametres/droits",
            permissions: ["kdix.actions.user.manage"],
          },
          {
            id: uuid(),
            label: "Identifiants utilisateurs Lokoa",
            path: "/parametres/lokoa",
            permissions: ["kdix.actions.user.manage"],
            module: "lokoa",
          },
        ],
      },
      {
        id: uuid(),
        label: "Véhicules",
        permissions: ["kdix.actions.alert_badge.edit"],
        children: [
          {
            id: uuid(),
            label: "Types de Véhicules",
            permissions: ["kdix.actions.alert_badge.edit"],
            path: "/parametres/types-vehicule",
          },
          {
            id: uuid(),
            label: "Types d'Actions",
            permissions: ["kdix.actions.alert_badge.edit"],
            path: "/parametres/types-action",
          },
        ],
      },
      {
        id: uuid(),
        label: "Prévention",
        permissions: ["kdix.actions.alert_badge.edit"],
        children: [
          {
            id: uuid(),
            label: "Localisations corporelles",
            permissions: ["kdix.actions.alert_badge.edit"],
            path: "/parametres/types-localisation-corporelle",
          },
          {
            id: uuid(),
            label: "Types d'accidents",
            permissions: ["kdix.actions.alert_badge.edit"],
            path: "/parametres/types-accident",
          },
          {
            id: uuid(),
            label: "Types de lieux",
            permissions: ["kdix.actions.alert_badge.edit"],
            path: "/parametres/types-lieu-accident",
          },
        ],
      },
      {
        id: uuid(),
        label: "Processus d'arrivée et de départ",
        permissions: [
          "kdix.actions.process_in_out.edit",
          "kdix.actions.process_in_out.edit.agency",
          "kdix.actions.process_in_out.edit.department",
          "kdix.actions.process_in_out.edit.service",
        ],
        children: [
          {
            id: uuid(),
            label: "Types de processus d'arrivée",
            path: "/processus/entree",
            permissions: [
              "kdix.actions.process_in_out.edit",
              "kdix.actions.process_in_out.edit.agency",
              "kdix.actions.process_in_out.edit.department",
              "kdix.actions.process_in_out.edit.service",
            ],
          },
          {
            id: uuid(),
            label: "Types de processus de départ",
            path: "/processus/sortie",
            permissions: [
              "kdix.actions.process_in_out.edit",
              "kdix.actions.process_in_out.edit.agency",
              "kdix.actions.process_in_out.edit.department",
              "kdix.actions.process_in_out.edit.service",
            ],
          },
        ],
      },
    ],
  },
];
