import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map, reduce, filter as _filter } from "lodash-es";
import Alert from "../components/Alert";
import React, { useState } from "react";
import Button from "../components/Button";
import { useCreateOrUpdateMessage } from "../api/messages";
import { useQueryTypeMessage } from "../api/typeMessage";
import Select, { DisplaySelect } from "../components/Select";
import { toast } from "react-toastify";
import Radio from "../components/Radio";
import { ReactComponent as ApplauseIcon } from "../svgs/applause.svg";
import { ReactComponent as ThinkingIcon } from "../svgs/thinking.svg";
import {usePermissionsChecker, useUserId} from "../contexts/permissions";
import { EditAndValidButton } from "../components/HistoryItem/EditButton";
import Block from "../components/Block";
import { ReactComponent as CrossIcon } from "../svgs/cross.svg";
import { ReactComponent as FilterIcon } from "../svgs/filters.svg";
import { useMap, useToggle } from "react-use";
import { useQueryJobs } from "../api/jobs";
import { useQueryAgencies } from "../api/agencies";
import { useQueryServices } from "../api/services";
import { getFirstnameLastnameJob } from "../utils/names";
import Spinner from "../components/Spinner";
import SelectCollaborator from "../components/SelectCollaborator";
import {WysiwygTextEditor, WysiwygTextEditorNoForm} from "../components/WysiwygTextEditor";
import { useDesktop } from "../hooks/useDesktop";
import { useHistory } from "react-router-dom";
import DownloadOrUpload from "../components/DownloadOrUpload";
import {useCreateMessageDocument} from "../api/messageDocument";
import FileDownloadLine from "../components/Button/FileDownloadLine";
import {downloadMessageDocument} from "../api/messageDocument";
import {DateTimePickerInput} from "../components/Input";


const validation = Yup.object().shape({
  type: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  recipients: Yup.array().of(Yup.object()).min(1, "Requis"),
  copyRecipients: Yup.array().of(Yup.object()),
  comment: Yup.string().required("Requis"),
});

const transformSelect = (r) => {
  return {
    value: get(r, "@id"),
    label: getFirstnameLastnameJob(r, "collaborator"),
  };
};

function Filters({ toggleClose, currentFilter, setFilter, resetFilters }) {
  const { data: jobs } = useQueryJobs();
  const { data: agencies } = useQueryAgencies();
  const { data: services } = useQueryServices();
  const availableFilters = [
    {
      label: "Site",
      key: "agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Emploi",
      key: "currentContract.job",
      options: map(jobs, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Service",
      key: "service",
      options: map(services, (node) => ({
        label: node.code,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];
  return (
    <Block>
      <div onClick={toggleClose} className={"ml-auto cursor-pointer w-4"}>
        <CrossIcon width={"27px"} height={"27px"} />
      </div>
      <div className={"flex flex-wrap px-4 justify-around gap-10"}>
        {map(availableFilters, (filter) => {
          return (
            <DisplaySelect
              isMulti
              key={filter.key}
              label={filter.label}
              name={filter.key}
              options={filter.options}
              value={get(currentFilter, filter.key, [])}
              onChange={(e) => {
                setFilter(filter.key, e);
              }}
              className={"flex-grow"}
            />
          );
        })}
      </div>

      <div>
        <div
          className="mt-3 underline cursor-pointer"
          onClick={() => {
            resetFilters();
          }}
        >
          Supprimer les filtres
        </div>
      </div>
    </Block>
  );
}

export default function MessageForm({
  onSuccess,
  message,
  isDisabled,
  isPrevention = false,
  isInformation = false,
}) {
  const [updateMessage, { error }] = useCreateOrUpdateMessage();
  const [createMessageDocument] = useCreateMessageDocument();
  const [messageType, setMessageType] = useState(null);
  const canSeeTypesWithSpecificRights = usePermissionsChecker({
    permissions:['kdix.actions.information_message.view']
  });
  const { data: unfilteredTypesMessage } = useQueryTypeMessage({
    active: true
  });

  // Pour une raison inconnue, le useQueryTypeMessage fetch avec un canSeeTypesWithSpecificRights forcément à false
  // avant que celui-ci ne passe à true, mais ne trigger pas le refetch du useQueryTypeMessage. On est donc obligé
  // de fetch sans le filtre puis de trier nous même ensuite
  const typesMessage = _filter(unfilteredTypesMessage, (type) => {
    if (canSeeTypesWithSpecificRights) {
      return true;
    }

    return type.withSpecificRights === false;
  })

  const userId = useUserId();
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});
  const [isEditing, setIsEditing] = useState(!message);
  const [filterOn, filterToggle] = useToggle(false);
  //Ajout des filtres
  const formattedFilters = {};
  for (const key in filters) {
    // check also if property is not inherited from prototype
    if (filters.hasOwnProperty(key)) {
      formattedFilters[key] = reduce(
        filters[key],
        (result, f) => {
          result.push(f.value);
          return result;
        },
        []
      );
    }
  }

  const [approved, setApproved] = useState(message ? true : null);
  const isDesktop = useDesktop();
  const history = useHistory();

  return (
    <div>
      <button
        className={`${
          isEditing ? "flex" : "hidden"
        } items-center focus:outline-none mb-4`}
        onClick={filterToggle}
      >
        <FilterIcon />
        <span className="ml-4">Filtres</span>
      </button>
      <div className={`${isEditing && filterOn ? "block" : "hidden"}`}>
        <React.Suspense fallback={<Spinner />}>
          <Filters
            setFilter={setFilter}
            removeFilter={removeFilter}
            resetFilters={resetFilters}
            currentFilter={filters}
            toggleClose={filterToggle}
          />
        </React.Suspense>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          comment: get(message, "comment", ""),
          type: {
            value: get(message, "type.@id", ""),
            label: get(message, "type.label", ""),
          },
          positive: get(message, "positive", true),
          sendDate: get(message, "sendDate", null),
          recipients: get(message, "recipients", false)
            ? map(message.recipients, transformSelect)
            : [],
          copyRecipients: get(message, "copyRecipients", false)
            ? map(message.copyRecipients, transformSelect)
            : [],
        }}
        validationSchema={validation}
        onSubmit={async (
          { type, recipients, copyRecipients, ...values },
          actions
        ) => {
          try {
            const response = await updateMessage({
              id: get(message, "id", null),
              data: {
                ...values,
                type: type.value,
                recipients: map(recipients, "value"),
                copyRecipients: map(copyRecipients, "value"),
                author: userId,
                approved: approved,
                information: isInformation,
              },
            });

            if (response?.id && values.file) {
              await createMessageDocument({
                file: values.file,
                messageId: response.id
              });
            }

            actions.setSubmitting(false);
            actions.resetForm();
            toast.success(
              `Message ${!message ? "envoyé" : "modifié"} avec succès`
            );
            if(!message) history.goBack();
            onSuccess();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          errors: err,
          values,
          setFieldValue,
          handleSubmit,
          resetForm,
        }) => {
          return (
            <Form>
                {!isDisabled && message && !isDesktop ? (
                <div className="pt-10">
                    <EditAndValidButton
                      isSubmitting={isSubmitting}
                      isEditing={isEditing}
                      labelDelete="Refuser"
                      permissionsEdit={["kdix.actions.message.moderate"]}
                      onDelete={() => {
                        setApproved(false);
                        handleSubmit();
                      }}
                      onCancel={() => {
                        resetForm();
                        setIsEditing(!isEditing);
                      }}
                      onClick={() => {
                        setIsEditing(!isEditing);
                      }}
                    />
                  </div>
                  ) : null }
              <SelectCollaborator
                isMulti
                allowSelectAll
                label={"Collaborateurs *"}
                name={`recipients`}
                isUser
                value={values.recipients}
                className={"mb-4"}
                isClearable
                isDisabled={!isEditing}
                queryFilters={formattedFilters}
              />
              <SelectCollaborator
                isMulti
                label="Collaborateurs en copie"
                name={`copyRecipients`}
                isUser
                value={values.copyRecipients}
                className={"mb-4"}
                isClearable
                isDisabled={!isEditing}
                queryFilters={formattedFilters}
              />
              <div className={isPrevention ? "hidden" : ""}>
                <Select
                  name="type"
                  placeholder="Type de message *"
                  options={map(typesMessage, (typeMessage) => ({
                    label: typeMessage.label,
                    value: typeMessage["@id"],
                    noModeration: typeMessage.noModeration,
                    noPositivity: typeMessage.noPositivity,
                    withSpecificRights: typeMessage.withSpecificRights,
                  }))}
                  value={values.type.value !== "" ? values.type : null}
                  isDisabled={!isEditing}
                  isLoading={!typesMessage}
                  onChange={(type) => {
                    setMessageType(type);
                    setApproved(type.noModeration || message ? true : null);
                  }}
                />
              </div>
              <div className="flex ">
                {(messageType && !messageType?.noPositivity) || (!messageType && message && !get(message, "type.noPositivity", false)) ? (
                    <>   
                        <Radio
                            type="checkbox"
                            name="positive"
                            value={true}
                            readOnly={!isEditing}
                            checked={values.positive}
                            Icon={ApplauseIcon}
                            title={isDesktop ? "Félicitez, remerciez le travail réalisé, le retour d'un client, l'engagement du collaborateur. Cela est une source inestimable pour les entretiens." : ""}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              if (checked) {
                                setFieldValue("positive", true);
                              }
                            }}
                        />
                        <Radio
                            type="checkbox"
                            name="positive"
                            value={false}
                            color="purple-400"
                            readOnly={!isEditing}
                            checked={!values.positive}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              if (checked) {
                                setFieldValue("positive", false);
                              }
                            }}
                            Icon={ThinkingIcon}
                            title={isDesktop ? "Un point de progrès est une invitation à faire autrement en donnant la raison et les conséquences.Alors, commencez votre phrase par 'Je vous invite, je vous conseille, je pense, ...'" : "" }
                        />
                    </>
                ) : (
                  <>
                  { messageType && messageType?.noPositivity && !isDisabled && !message ? (
                    <DateTimePickerInput
                    disabled={!isEditing}
                    name="sendDate"
                    label={isEditing ? "Programmer l'envoi à :":"Envoi programmé à :"}
                    disableClock={false}
                />
                ) : null }
                </>
                )}
              </div>
              {isEditing ? (
              <DownloadOrUpload
                  uploadLabel={"Ajouter un fichier"}
                  name="file"
                  accept="application/pdf, application/docx, image/jpeg, image/png, image/gif, image/webm"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                  downloadFn={!!get(message, "messageDocument", false)}
              />
              ) : message?.messageDocument ? (
              <FileDownloadLine
                label="Télécharger le fichier joint"
                downloadFn={downloadMessageDocument.bind(
                    null,
                    message?.messageDocument.id,
                    message?.messageDocument.label,
                    message?.messageDocument.extension
                )}
               />
              ) : null}
             {isEditing ? (
              <WysiwygTextEditor
                  name="comment"
                  label={message ? "Message *" : "Votre message *"}
                  setFieldValue={(val) => setFieldValue("comment", val)}
                  value={values.comment}
                  readOnly={!isEditing}
                  toolbarHidden={!isEditing}
                  toolbarMini={!isDesktop}
              />
              ) : (
              <WysiwygTextEditorNoForm
                  value={values.comment}
                  readOnly={!isEditing}
              />
              )}
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              {!isDisabled ? (
                <>
                  {message ? (
                    <>
                       {isDesktop ? (
                            <div className="pt-10">
                              <EditAndValidButton
                                isSubmitting={isSubmitting}
                                isEditing={isEditing}
                                labelDelete="Refuser"
                                permissionsEdit={["kdix.actions.message.moderate"]}
                                onDelete={() => {
                                  setApproved(false);
                                  handleSubmit();
                                }}
                                onCancel={() => {
                                  resetForm();
                                  setIsEditing(!isEditing);
                                }}
                                onClick={() => {
                                  setIsEditing(!isEditing);
                                }}
                              />
                            </div>
                       ) : null}
                    </>
                  ) : (
                    <Button
                      className={`btn mt-6 w-full block`}
                      readOnly={!isEditing}
                      disabled={isSubmitting}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                      textLabel="Envoyer"
                    />
                  )}
                </>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
