import {
  useCreateTypeMessage,
  useQueryTypeMessage,
  useUpdateTypeMessage,
} from "../api/typeMessage";

import { useIsModuleActive } from "../contexts/permissions";
import ManageResource from "../components/ManageResource";
import React from "react";

function TypeMessageAdmin() {
	const withMessagesInfoModule = useIsModuleActive("information_messages");
	
  return (
    <ManageResource
      resourceLabel="Types de messages"
      createLabel="Ajouter un type de message"
      noElementsLabel="Aucun type de message"
      createHook={useCreateTypeMessage}
      queryHook={useQueryTypeMessage}
      updateHook={useUpdateTypeMessage}
      canDeactivate={true}
      canDelete={false}
      denominationLabel="Type de message"
      params={withMessagesInfoModule ? {
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
        prevention: {
            label: "Type prévention ?",
            type: "checkbox",
            resourceValueKey: "prevention",
        },
        noModeration: {
            label: "Message sans modérateur",
            type: "checkbox",
            resourceValueKey: "noModeration",
        },
        noPositivity: {
            label: "Message sans positivité",
            type: "checkbox",
            resourceValueKey: "noPositivity",
        },
        withSpecificRights: {
            label: "Message avec droits spécifiques",
            type: "checkbox",
            resourceValueKey: "withSpecificRights",
        },
      } : {
		label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
        prevention: {
            label: "Type prévention ?",
            type: "checkbox",
            resourceValueKey: "prevention",
        },
	}}
    />
  );
}

export default TypeMessageAdmin;
