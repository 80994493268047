import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { flatMap, map, size } from "lodash-es";
import { useQueryInfiniteRequests } from "../api/requests";
import { useUserIri } from "../contexts/permissions";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { RequestItem } from "./Requests/RequestItem";
import { useQueryPermissions } from "../api/permissions";

function RequestsList({ dateRange: { startDate, endDate }, filters, order }) {
  const filter =
    endDate !== null
      ? {
          "createdAt[after]": new Date(startDate),
          "createdAt[before]": new Date(endDate),
          "order[createdAt]": order,
        }
      : {
          "order[createdAt]": order,
        };

  const { report } = filters;
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  if (report) {
    if (report.length === 1 && report[0]) {
      filter["reminderAt[after]"] = now;
    } else if (report.length !== 2) {
      filter["reminderAt[before]"] = now;
    }
  }
  const userIri = useUserIri();

  const { data: permissions } = useQueryPermissions({ users: userIri });

  const { data, fetchMore, canFetchMore } = useQueryInfiniteRequests({
    ...filter,
    ...filters,
  });

  const requests = flatMap(data, (page) => page["hydra:member"]);

  if (size(requests) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune demande" />
      </div>
    );
  }

  return (
    <div>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(requests, (request) => {
          return (
            <RequestItem
              request={request}
              key={request["id"]}
              userIri={userIri}
              permissions={permissions}
              filters={{
                ...filter,
                ...filters,
              }}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default RequestsList;
