import React, {useState} from "react";
import { Editor } from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useField} from "formik";

export const WysiwygTextEditor = ({
                                      value,
                                      setFieldValue,
                                      label = null,
                                      forceError = false,
                                      readOnly = false,
                                      toolbarHidden = false,
                                      toolbarMini = false,
                                      withColorPen = true,
                                      isDesktop = false,
                                      ...props
                                  }) => {
    const [meta] = useField(props);

    const prepareDraft = (value) => {
        const draft = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(draft.contentBlocks, null);

        return EditorState.createWithContent(contentState);
    };

    const valueDraft = value ? prepareDraft(value) : EditorState.createEmpty();
    const [editorState, setEditorState] = useState(
        valueDraft
    );
    
    const onEditorStateChange = (editorState) => {
        const forFormik = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );
        setFieldValue(forFormik);
        setEditorState(editorState);
    };
    return (
        <div>
            {label ? (
                <label
                    className={`block ${
                        (meta.touched && meta.error) || forceError
                            ? "text-red-500"
                            : `text-black`
                    } text-sm font-bold`}
                >
                    {label}
                </label>
            ) : null}
            {toolbarMini ? (
                <Editor
                    readOnly={readOnly}
                    toolbarHidden={toolbarHidden}
                    editorState={editorState}
                    wrapperClassName="wysiwyg-wrapper"
                    editorClassName={"wysiwyg-editor" + isDesktop ? "-mobile" : ""}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
			    options: ['inline'],
			    inline: {
                               inDropdown: false,
                               options: ['bold', 'italic', 'underline'],
                            },
		    }}
            />
            ) : ( withColorPen ? (
                <Editor
                    readOnly={readOnly}
                    toolbarHidden={toolbarHidden}
                    editorState={editorState}
                    wrapperClassName="wysiwyg-wrapper"
                    editorClassName="wysiwyg-editor"
                    onEditorStateChange={onEditorStateChange}
                />
            ) : (
               <Editor
                    readOnly={readOnly}
                    toolbarHidden={toolbarHidden}
                    editorState={editorState}
                    wrapperClassName="wysiwyg-wrapper"
                    editorClassName="wysiwyg-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
			    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji'],
		    }}
                />
                ))}
        </div>
    );
};

export const WysiwygTextEditorNoForm = ({value, label = null, readOnly = false}) => {
    const prepareDraft = (value) => {
        const draft = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(draft.contentBlocks, null);

        return EditorState.createWithContent(contentState);
    };

    const valueDraft = value ? prepareDraft(value) : EditorState.createEmpty();
    const [editorState, setEditorState] = useState(valueDraft);

    if (valueDraft.getCurrentContent().getPlainText() !== editorState.getCurrentContent().getPlainText()) {
        setEditorState(valueDraft);
    }

    return (
        <div>
            {label ? (
                <label
                    className={`block text-black text-sm font-bold`}
                >
                    {label}
                </label>
            ) : null}

            <Editor
                toolbarHidden={readOnly}
                readOnly={readOnly}
                editorState={editorState}
            />
        </div>
    );
};
