import React, { useState } from "react";
import { Form, Formik } from "formik";
import { get, map, noop } from "lodash-es";
import { toast } from "react-toastify";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useUpdateCollaborator } from "../api/collaborator";
import { getFirstnameLastnameJob } from "../utils/names";
import { DisplaySelect } from "../components/Select";
import * as Yup from "yup";
import Block from "../components/Block";
import { ReactComponent as CrossIcon } from "../svgs/cross.svg";
import SelectCollaborator from "../components/SelectCollaborator";
import { useQueryValidatorById } from "../api/validator";
import CollaboratorFilters from "../components/Filter/CollaboratorFilters";

function getDatasUpdate(preValidatorForm, { collaboratorsCanBeValidate }) {
  if (preValidatorForm) {
    return {
      collaboratorsCanBePreValidate: map(collaboratorsCanBeValidate, "value"),
    };
  }
  return {
    collaboratorsCanBeValidate: map(collaboratorsCanBeValidate, "value"),
  };
}
function getDatasDelete(preValidatorForm) {
  if (preValidatorForm) {
    return {
      collaboratorsCanBePreValidate: [],
    };
  }
  return {
    collaboratorsCanBeValidate: [],
  };
}
const transformSelect = (collaborator, isIri = true) => {
  return {
    value: get(collaborator, isIri ? "@id" : "id"),
    label: getFirstnameLastnameJob({ collaborator }, "collaborator"),
  };
};

const schema = Yup.object().shape({
  validator: Yup.object().shape({
    label: Yup.string().required("Ce champ est requis"),
  }),
  collaboratorsCanBeValidate: Yup.array()
    .of(Yup.object())
    .min(1, "Ce champ est requis"),
});

export function Filters({
  AvailableFilters,
  toggleClose,
  currentFilter,
  setFilter,
  resetFilters,
  isModal,
}) {
  return (
    <Block>
      <div onClick={toggleClose} className={"ml-auto cursor-pointer w-4"}>
        <CrossIcon width={"27px"} height={"27px"} />
      </div>
      <div
        className={`flex ${
          isModal ? "flex-col" : "flex-wrap"
        } px-4 justify-around gap-10`}
      >
        {map(AvailableFilters, (filter) => {
          return (
            <DisplaySelect
              isMulti
              key={filter.key}
              label={filter.label}
              name={filter.key}
              options={filter.options}
              value={get(currentFilter, filter.key, [])}
              onChange={(e) => {
                setFilter(filter.key, e);
              }}
              className={"flex-grow min-w-200p"}
            />
          );
        })}
      </div>

      <div>
        <div
          className="mt-3 underline cursor-pointer"
          onClick={() => {
            resetFilters();
          }}
        >
          Supprimer les filtres
        </div>
      </div>
    </Block>
  );
}

export default function ValidatorForm({
  validator = null,
  readOnly = false,
  onSuccess = noop,
  preValidatorForm = false,
  isModal = false,
  isOpen,
}) {
  const { data: collaborator } = useQueryValidatorById(isOpen && validator.id);

  const [formatedFilters, setFormatedFilters] = useState({});
  const initialCanBeValidate = get(
    collaborator,
    preValidatorForm
      ? "collaboratorsCanBePreValidate"
      : "collaboratorsCanBeValidate",
    [],
  );

  const queryFilters = { "exists[linkedUser]": true };
  if (preValidatorForm) {
    queryFilters["exists[collaboratorsCanBePreValidate]"] = false;
  } else {
    queryFilters["exists[collaboratorsCanBeValidate]"] = false;
  }

  const [updateValidator, { error, status }] = useUpdateCollaborator();
  return (
    <div>
      <CollaboratorFilters
        readOnly={readOnly}
        onChange={setFormatedFilters}
        isModal={isModal}
      />
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          validator: {
            value: get(validator, "id", ""),
            label: validator ? getFirstnameLastnameJob(validator) : "",
          },
          collaboratorsCanBeValidate: map(initialCanBeValidate, (v) => {
            //Si on peut se valider soit même, le collab n'est pas sérialisé à ce niveau,
            // on récupère les infos au niveau valideur
            if (typeof v === "string") {
              if (v === get(validator, "@id", "")) {
                return {
                  value: get(validator, "@id", ""),
                  label: validator ? getFirstnameLastnameJob(validator) : "",
                };
              }
            } else {
              return {
                value: v["@id"],
                label: getFirstnameLastnameJob(v),
              };
            }
          }),
        }}
        onSubmit={async (values, actions) => {
          try {
            //Si on a déjà un valideur et qu'il a été modifié on supprime les valeurs de l'ancien
            if (
              validator &&
              get(validator, "id", "") !== values.validator.value
            ) {
              await updateValidator({
                id: get(validator, "id", ""),
                data: getDatasDelete(preValidatorForm),
              });
            }
            await updateValidator({
              id: values.validator.value,
              data: getDatasUpdate(preValidatorForm, values),
            });
            toast.success("Mise à jour effectuée avec succès");
            onSuccess();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  className={`btn btn--sm`}
                  readOnly={readOnly}
                  isSubmitting={status === "loading" || isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel={validator ? "Enregistrer" : "Créer"}
                />
                {validator && (
                  <Button
                    type="button"
                    className={`btn btn--error btn--sm`}
                    readOnly={readOnly}
                    isSubmitting={status === "loading" || isSubmitting}
                    onClick={async () => {
                      const res = window.confirm(
                        "La suppression sera définitive",
                      );
                      if (res) {
                        await updateValidator({
                          id: values.validator.value,
                          data: getDatasDelete(preValidatorForm),
                        });
                      }
                    }}
                  >
                    Supprimer
                  </Button>
                )}
              </div>

              <SelectCollaborator
                name="validator"
                queryFilters={queryFilters}
                label={preValidatorForm ? "Pré-Valideur" : "Valideur"}
                value={values.validator}
                readOnly={readOnly}
                valueId
                addOptions={
                  validator ? [transformSelect(validator, false)] : []
                }
              />
              <SelectCollaborator
                name="collaboratorsCanBeValidate"
                label="Collaborateurs liés"
                value={values.collaboratorsCanBeValidate}
                isMulti
                readOnly={readOnly}
                allowSelectAll
                queryFilters={formatedFilters}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
