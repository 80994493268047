import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input, { DateTimePickerInput } from "../components/Input";
import Button from "../components/Button";
import SelectCollaborator from "../components/SelectCollaborator";
import CollaboratorFilters from "../components/Filter/CollaboratorFilters";
import { get, map } from "lodash-es";
import { useCreateOrUpdateCampaign } from "../api/campaign";
import Alert from "../components/Alert";
import { useHistory } from "react-router-dom";

const validation = Yup.object().shape({
  name: Yup.string().required("Requis"),
  startDate: Yup.date().required("Requis"),
  endDate: Yup.date().required("Requis"),
  collaborators: Yup.array().of(Yup.string()).min(1, "au moins un collaborateur est nécessaire"),
});

export default function CreateCampaign() {
  const [filters, setFilters] = useState({});
  const [createCampaign, { error }] = useCreateOrUpdateCampaign();

  const history = useHistory();
  
  var dateNextMonth = new Date();
  dateNextMonth.setDate(dateNextMonth.getDate()+90);
 
  return (
    <div>
      <CollaboratorFilters onChange={setFilters} />
      {error ? (
        <div className="my-2">
          <Alert
            type="error"
            message={get(error, "title")}
            details={get(error, "description")}
          />
        </div>
      ) : null}
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          startDate: new Date(),
          endDate: dateNextMonth,
          collaborators: [],
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          console.log("submit", { values });
          try {
            await createCampaign({
              id: null,
              data: {
                name: values.name,
                campaignEvaluations: [
                  ...map(values.collaborators, ({ value }) => ({
                    collaborator: value,
                  })),
                ],
                startDate: values.startDate,
                endDate: values.endDate,
              },
            });
            
            history.push(`/rh/competences/suivi_campagnes`);
            
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Input type="text" name="name" label="Nom" />
              <DateTimePickerInput name="startDate" label="Date de début" />
              <DateTimePickerInput name="endDate" label="Date de fin" />
              <SelectCollaborator
                name="collaborators"
                label="Collaborateurs"
                value={values.collaborators}
                isMulti={true}
                filterNoEvaluator={true}
                allowSelectAll={true}
                queryFilters={filters}
              />
              <Button
                className={`btn mt-6 w-full block`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Lancer les évaluations"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
