import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, {
  deleteById,
  updateById,
  updateOrCreate,
} from "./index";

import { get } from "lodash-es";
import type { Permission } from "../types/permission";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "permissions";
//
type FiltersType = {
  pagination?: boolean;
  page?: number | null;
  users?: string | null;
  isAdmin?: boolean | null;
  "order[label]"?: string | null;
};

export function useQueryPermissions(filters?: FiltersType) {
  const { data, ...rest } = useQuery<
    Permissions,
    [string, FiltersType?],
    Error
  >(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member", []) as Permission[],
    totalItems: get(data, "hydra:totalItems", 0) as number,
    ...rest,
  };
}
/*
export function useCreatePermission() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}*/

export function useUpdatePermission() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}
/*
const addRemoveActionById = (key, { id, actions, type }) => {
  if (type !== "add" && type !== "remove")
    throw new Error('Type must be "add" or "remove"');
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${key}/action/${type}/${id}`,
    {
      method: "PATCH",
      data: {
        actions_list: actions,
      },
    }
  );
};*/
/*
export function useAddRemoveActionsPermission() {
  return useMutation(addRemoveActionById.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}*/

export function useCreateOrUpdatePermission() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}

export function useDeletePermission() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: (data) => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}
