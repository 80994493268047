import React, { useState } from "react";
import {
  PermissionChecker,
  useCollaboratorIri,
  usePermissionsChecker,
} from "../../contexts/permissions";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import { CollaboratorContext } from "../../contexts/collaborator";
import { useDesktop } from "../../hooks/useDesktop";
import { InlineBLockContent } from "../../components/InlineBlockContentSortable";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { DisplaySelect } from "../../components/Select";
import { DisplayInput, DateTimePickerInput } from "../../components/Input";
import { find, map, get, noop, size } from "lodash-es";
import {
  useCreateEvaluation,
  useUpdateEvaluation,
  useDeleteEvaluation
} from "../../api/evaluations";
import { useGetCollaboratorEvaluations } from "../../api/collaborator";
import { useGetUserYearsEvaluations } from "../../api/user";
import { Link, useHistory } from "react-router-dom";
import {
  EVALUATION_STATUS,
  EVALUATION_STATUS_CLOSED,
  EVALUATION_STATUS_COMPLETED,
  EVALUATION_STATUS_IN_PROGRESS,
} from "../../utils/evaluationStatus";
import { getFirstnameLastname, getOnlyWork } from "../../utils/names";
import { ReactComponent as LockIcon } from "../../svgs/lock.svg";
import { ReactComponent as StatsIcon } from "../../svgs/stats.svg";
import { ReactComponent as PenIcon } from "../../svgs/pen.svg";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import InfoTooltip from "../../components/InfoTooltip";
import Textarea from "../../components/TextArea";
import { Form, Formik } from "formik";
import { getLocaleDateString } from "../../utils/date";
import StarScore from "../../components/StarScore";
import {useCreateOrUpdateEvent} from "../../api/events";


function YearFilter({ idUser, currentYear, onChange = noop }) {
  const { data: years } = useGetUserYearsEvaluations(idUser);

  return (
    <div>
      <DisplaySelect
        label="Filtrer par année"
        options={[
          ...map(years, (y) => ({ label: y.year, value: y.year })),
          { label: "TOUT", value: null },
        ]}
        value={{ label: currentYear, value: currentYear }}
        onChange={onChange}
      />
    </div>
  );
}

export default function Evaluation() {
  const {
    collaborator: { data: collaborator },
  } = React.useContext(CollaboratorContext);

  const currentCollaboratorIri = useCollaboratorIri();

  const now = new Date();
  const [currentYear, setCurrentYear] = React.useState(now.getFullYear());
  const dateFilters =
    null !== currentYear
      ? {
          "createdAt[after]": new Date(currentYear, 0, 1, 0, 0, 0),
          "createdAt[before]": new Date(currentYear, 11, 31, 23, 59, 59),
        }
      : {
          "createdAt[after]": new Date(1980, 0, 1, 0, 0, 0),
          "createdAt[before]": new Date(3000, 11, 31, 23, 59, 59),
        };
  const [modalFilters, setModalFiltersOpen] = React.useState(false);

  const hasAutoEvaluation = usePermissionsChecker({
    permissions: ["kdix.actions.skill_evaluation.auto_evaluation"],
  });
  const isDesktop = useDesktop();
  const gridTemplateColumn = "repeat(8, 1fr)";

  const { data: evaluations } = useGetCollaboratorEvaluations(
    collaborator?.id,
    dateFilters,
  );
  
  const evaluationsInProgess = evaluations.filter(
          (evaluation) => evaluation.status === EVALUATION_STATUS_IN_PROGRESS
          );

  const [closedEvaluation, setClosedEvaluation] = useState(null);
  const [updateEvaluation] = useUpdateEvaluation();
  const [deleteEvaluation] = useDeleteEvaluation();
  const [createEvent] = useCreateOrUpdateEvent();

  const isEvaluator = !!find(collaborator.evaluators, {
    "@id": currentCollaboratorIri,
  });
  const idUser = get(collaborator, "linkedUser.id", null);
  const canSeeViewButton = (node) =>
    node.status === EVALUATION_STATUS_CLOSED ||
    (node.status === EVALUATION_STATUS_COMPLETED && isEvaluator);

  return (
    <PermissionChecker
      permissions={[
        "kdix.actions.skill_evaluation.view_evaluations",
        "kdix.actions.skill_evaluation.view_evaluations.agency",
        "kdix.actions.skill_evaluation.view_evaluations.department",
        "kdix.actions.skill_evaluation.view_evaluations.service",
        "kdix.actions.skill_evaluation.view_evaluations.own",
      ]}
    >
      {isDesktop && (
        <>
          {idUser !== null && (
            <div className="flex justify-left mb-6 items-center">
              <button
                className="flex items-center focus:outline-none"
                onClick={() => {
                  setModalFiltersOpen(true);
                }}
              >
                <FilterIcon />
                <span className="ml-4">Filtres</span>
              </button>
            </div>
          )}
          <div
            className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
            style={{
              gridTemplateColumns: gridTemplateColumn,
            }}
          >
            <InlineBLockContent>Date de l'auto-évaluation</InlineBLockContent>
            <InlineBLockContent>Statut</InlineBLockContent>
            <InlineBLockContent>Évaluateur</InlineBLockContent>
            <InlineBLockContent>Date de l'évaluation</InlineBLockContent>
            <InlineBLockContent>Poste</InlineBLockContent>
            <InlineBLockContent>Vision de l'évalué</InlineBLockContent>
            <InlineBLockContent>Vision de l'évaluateur</InlineBLockContent>
            <InlineBLockContent>Actions</InlineBLockContent>
          </div>
        </>
      )}
      {map(evaluations, (node) =>
        isDesktop ? (
          <div
            key={node.id}
            className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
            style={{
              gridTemplateColumns: gridTemplateColumn,
            }}
          >
            <InlineBLockContent>
              {getLocaleDateString(node, "createdAt")}
            </InlineBLockContent>
            <InlineBLockContent>
              {EVALUATION_STATUS[node.status]}
            </InlineBLockContent>
            <InlineBLockContent>
              {map(node.evaluators, (e, index) => (
                <span key={index}>{getFirstnameLastname(e)}</span>
              ))}
            </InlineBLockContent>
            <InlineBLockContent>
              {getLocaleDateString(node, "evaluatorDate")}
            </InlineBLockContent>
            <InlineBLockContent>
              {getOnlyWork(node.collaborator)}
            </InlineBLockContent>
            <InlineBLockContent className="flex justify-center">
              { (currentCollaboratorIri === collaborator["@id"] || node.status === EVALUATION_STATUS_COMPLETED || node.status === EVALUATION_STATUS_CLOSED ) && (
                <StarScore score={node.scoreCollaborator} />
              )}
            </InlineBLockContent>
            <InlineBLockContent className="flex justify-center">
              { (isEvaluator || node.status === EVALUATION_STATUS_COMPLETED || node.status === EVALUATION_STATUS_CLOSED) && (
              <StarScore
                score={node.scoreEvaluator}
                colorStar="text-purple-600"
              />
              )}
            </InlineBLockContent>
            <InlineBLockContent className="flex justify-center gap-2">
              {node.status === EVALUATION_STATUS_IN_PROGRESS &&
                (currentCollaboratorIri === collaborator["@id"] ||
                  isEvaluator) && <EditButton id={node.id} />}
              {canSeeViewButton(node) && <ViewButton id={node.id} />}
              {node.status === EVALUATION_STATUS_COMPLETED && isEvaluator && (
                <ClosedButton id={node.id} setIsOpen={setClosedEvaluation} />
              )}
              {isEvaluator && (
                <button
                className="bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 flex justify-center items-center focus:outline-none"
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const res = window.confirm("La suppression sera définitive");
                  if (res) {
                    try {
                      await deleteEvaluation(node.id);
                      window.location.reload();
                    } catch (error) {
                      console.warn(error);
                    }
                  }
                }}
              >
                <TrashIcon className="w-4 h-4 text-white fill-current" />
              </button>
      
              )}
            </InlineBLockContent>
          </div>
        ) : (
          <div></div>
        ),
      )}

      {isDesktop &&
        hasAutoEvaluation &&
        currentCollaboratorIri === collaborator["@id"] &&
        size(evaluationsInProgess) === 0 && (
          <StartEvaluation collaborator={collaborator} isEvaluator={false} />
        )}

      {isDesktop &&
        isEvaluator &&
        currentCollaboratorIri !== collaborator["@id"] &&
        size(evaluationsInProgess) === 0 && (
          <StartEvaluation collaborator={collaborator} isEvaluator={true} />
        )}

      <Modal
        isOpen={closedEvaluation !== null}
        onRequestClose={() => setClosedEvaluation(null)}
        title="Clôturer une évaluation"
      >
        <Formik
          initialValues={{ commentCompleted: "", dateEvent: "" }}
          onSubmit={async (values, actions) => {
            await updateEvaluation({
              id: closedEvaluation,
              data: {
                commentCompleted: values.commentCompleted,
                ...values,
                status: EVALUATION_STATUS_CLOSED,
              },
            });
           
            if (values.dateEvent !== "") {
              
                await createEvent({
                    id: null,
                    data: {
                        label: "Entretien d'évaluation",
                        comment: values.commentCompleted,
                        collaborators: [collaborator['@id'], currentCollaboratorIri],
                        startDate: values.dateEvent,
                    },
                });
            }
          
            actions.setSubmitting(false);
            setClosedEvaluation(null);
            window.location.reload();
          }}
        >
          {({ isSubmitting, setSubmitting }) => (
            <Form>
              <DateTimePickerInput
                    name="dateEvent"
                    label="Date de l'entretien"
                  />
              <Textarea
                label="Commentaire"
                name="commentCompleted"
                textareaClassName="bg-gray-60"
              />
              <div className="mt-8">
                <Button
                  className={`btn btn--sm align-top w-half ml-4`}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Valider"
                />
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                  onClick={() => setClosedEvaluation(null)}
                >
                  Annuler
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        title="Filtres"
        handleClose={(e) => {
          e.stopPropagation();
          setModalFiltersOpen(false);
        }}
        isOpen={modalFilters}
        onRequestClose={() => setModalFiltersOpen(false)}
      >
        <YearFilter
          idUser={idUser}
          onChange={(e) => {
            setCurrentYear(e.value);
          }}
          currentYear={currentYear}
        />

        <button
          className="btn mt-5 w-full"
          type="button"
          onClick={() => setModalFiltersOpen(false)}
        >
          Appliquer les filtres
        </button>
      </Modal>
    </PermissionChecker>
  );
}

function EditButton({ id }) {
  return (
    <InfoTooltip message={"Démarrer/continuer l'évaluation"} onHover={true}>
      <Link
        to={`/evaluation/${id}`}
        className={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700`}
      >
        <PenIcon className="w-4 h-4 fill-current text-white" />
      </Link>
    </InfoTooltip>
  );
}
function ViewButton({ id }) {
  return (
    <InfoTooltip message={"Voir le rapport comparatif"} onHover={true}>
      <Link
        to={`/rapport_evaluation/${id}`}
        className={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700`}
      >
        <StatsIcon className="w-4 h-4 fill-current text-white" />
      </Link>
    </InfoTooltip>
  );
}
function ClosedButton({ setIsOpen, id }) {
  return (
    <InfoTooltip message={"Clôturer l'évaluation"} onHover={true}>
      <Button
        type="button"
        superClassName={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-red-600`}
        loaderClassName={`loader h-6 w-6 mx-auto`}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(id);
        }}
      >
        <LockIcon className="w-4 h-4 fill-current text-white" />
      </Button>
    </InfoTooltip>
  );
}

function StartEvaluation({ collaborator, isEvaluator }) {
  const [modalIsOpen, setIsOpen] = useState(false);

  const workDefined =
    get(collaborator, "work[@id]", false) === false ? false : true;

  const [createEvaluation] = useCreateEvaluation();
  const history = useHistory();
  return (
    <>
      <div className="text-center mt-10">
        <Button className={`btn`} type="button" onClick={() => setIsOpen(true)}>
          {isEvaluator ? "Démarrer une évaluation" : "Démarrer une auto-évaluation"}
        </Button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={workDefined ? "Démarrer une auto-évaluation" : "Attention"}
      >
        {workDefined ? (
          <>
            <DisplayInput
              label="Type de poste"
              value={get(collaborator, "work.label", "")}
            />
            <div className="flex gap-5 mt-20">
              <Button
                className={`btn--sm w-xlBtn`}
                type="button"
                onClick={async () => {
                  const evaluation = await createEvaluation({
                    collaborator: collaborator["@id"],
                    work: get(collaborator, "work[@id]", false),
                  });
                  setIsOpen(false);
                  history.push(`/evaluation/${evaluation.id}`);
                }}
              >
                Démarrer
              </Button>
              <Button
                className={`btn--sm btn--error w-xlBtn`}
                type="button"
                onClick={() => setIsOpen(false)}
              >
                Annuler
              </Button>
            </div>
          </>
        ) : (
          <div className="mt-3 text-xl font-black">
            Veuillez définir le poste du collaborateur
          </div>
        )}
      </Modal>
    </>
  );
}
