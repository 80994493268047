import { useGetUserAbsenceNotifications } from "../api/user";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  PermissionChecker,
  useCollaboratorIri,
  useUserId,
} from "../contexts/permissions";
import { get, map, pull, size } from "lodash-es";
import Alert from "./Alert";
import Block from "./Block";
import { useMap, useToggle } from "react-use";
import { getFirstnameLastnameJob } from "../utils/names";
import { getMessageLabel, getTransitionLabel } from "../utils/absenceStatus";
import { ReactComponent as FilterIcon } from "../svgs/filters.svg";
import Modal from "./Modal";
import CheckboxGroup from "./CheckboxGroup";
import { Link } from "react-router-dom";
import { useUpdateAbsenceNotification } from "../api/absenceNotification";
import Button from "./Button";

function getValidators(validatorsList, validatorType, isDelayPassed) {
  if (!isDelayPassed) {
    return map(validatorsList, (validator) => {
      if (validator["@id"]) {
        return validator["@id"]
      }

      return validator;
    });
  }

  let validators = [];

  for (const validator of validatorsList) {
    if (!validators.includes(validator['@id'])) {
      validators.push(validator['@id']);
    }

    for (const validatorOfValidator of get(validator, validatorType, [])) {
      if (typeof validatorOfValidator === "string" && !validators.includes(validatorOfValidator)) {
        validators.push(validatorOfValidator);
      } else if (typeof validatorOfValidator !== "string" && !validators.includes(validatorOfValidator['@id'])) {
        validators.push(validatorOfValidator['@id']);
      }
    }
  }

  return validators;
}

function AbsenceNotificationsItem({ notification }) {
  const [on, toggle] = useToggle(false);
  const collaboratorIri = useCollaboratorIri();
  const [isDisplayed, setIsDisplayed] = useState(true);
  const [updateHook, { error }] = useUpdateAbsenceNotification();
  const status = get(notification, "absence.status");

  const prevalidators = getValidators(
      get(notification, "absence.collaborator.prevalidators", []),
      "prevalidators",
      get(notification, "message") === "absence_notification.delay_prevalidation_has_passed"
  );

  const validators = getValidators(
      get(notification, "absence.collaborator.validators", []),
      "validators",
      get(notification, "message") === "absence_notification.delay_validation_has_passed"
  );

  let canEdit = false;
  if (status === "created") {
    if (get(notification, "absence.type.needPreValidation", false)) {
      canEdit = prevalidators.includes(collaboratorIri);
    } else {
      canEdit = validators.includes(collaboratorIri);
    }
  } else if (status === "prevalidated") {
    canEdit = validators.includes(collaboratorIri);
  }
  if (!isDisplayed) {
    return null;
  }
  const [messageLabel, messageType] = getMessageLabel(
    get(notification, "message", ""), notification
  );
  const messageTypeAsArray = Array.isArray(messageType) ? messageType : [messageType];

  return (
    <Block className={"cursor-pointer"}>
      <div
        className={"flex"}
        onClick={get(notification, "read", false) ? () => {
          toggle();
        } : async () => {
          toggle();
          notification.read = true;
          try {
            await updateHook({
              id: get(notification, "id"),
              data: {
                read: true,
              },
            });
          } catch (error) {}
        }}
      >
        <div className={"lg:flex w-full"}>
          <div>
            <p className={`font-bold`}>
              {get(notification, "absence.type.label", "")}
            </p>
            <p>
              {getFirstnameLastnameJob(notification, "absence.collaborator")}
            </p>
            <p>
              {`À partir du : ${
                notification.absence.startDate
                  ? new Date(
                      notification.absence.startDate
                    ).toLocaleDateString()
                  : ""
              }
      ${
        notification.absence.endDate
          ? " jusqu'au : " +
            new Date(notification.absence.endDate).toLocaleDateString()
          : ""
      }
      `}
            </p>

            <div className={"flex flex-wrap text-white"}>
              {map(Array.isArray(messageLabel) ? messageLabel : [messageLabel], (label, index) => {
                return (
                    <div
                        className={`${
                            messageTypeAsArray[index] === "warning"
                                ? "bg-orange-250"
                                : messageTypeAsArray[index] === "success"
                                ? "bg-green-800"
                                : "bg-red-800"
                        } px-2 py-1 mb-1 mr-1 rounded`}
                        key={notification.id + '-' + index}
                    >
                      {label}
                    </div>
                )
              })}
              
            </div>
          </div>
        </div>
        <div className={"lg:w-1/6 flex flex-col items-end md:ml-0"}>
          <div className={"text-right"}>
            <svg
              className={`transform fill-current text-green-600 ${
                on ? "rotate-180" : ""
              }`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={`mt-2`}>
        <div className={`${on ? "block" : "hidden"} mt-2 cursor-default`}>
          {notification.absence?.comment && (
            <div>
              <strong>Motif :</strong> {notification.absence.comment}
            </div>
          )}
          {status !== "created" && (
            <>
              <div>
                {map(
                  get(notification, "absence.history", []),
                  (historyItem) => {
                    return (
                      <div
                        key={historyItem.id}
                        className={
                          "border-solid border-b border-black last:border-b-0 py-3"
                        }
                      >
                        <p>
                          Votre demande du{" "}
                          {new Date(
                            notification.absence.createdAt
                          ).toLocaleDateString()}{" "}
                          a été{" "}
                          <span className={"font-bold"}>
                            {getTransitionLabel(
                              get(historyItem, "transition", "")
                            ).toLowerCase()}
                          </span>{" "}
                          par{" "}
                          {getFirstnameLastnameJob(historyItem, "collaborator")}{" "}
                          le{" "}
                          {get(historyItem, "date")
                            ? new Date(
                                get(historyItem, "date")
                              ).toLocaleDateString()
                            : ""}
                        </p>
                        {get(historyItem, "comment", false) ? (
                          <p>Commentaire : {get(historyItem, "comment", "")}</p>
                        ) : null}
                      </div>
                    );
                  }
                )}
              </div>

              {get(notification, "absence.history", []).length === 0
                ? "Aucun commentaire"
                : null}
            </>
          )}
          {get(notification, "viewed", false) !== true ? (
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                try {
                  await updateHook({
                    id: get(notification, "id"),
                    data: {
                      viewed: true,
                    },
                  });
                  setIsDisplayed(false);
                } catch (error) {}
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    {error ? (
                      <div className="my-2">
                        <Alert
                          type="error"
                          message={error.title}
                          details={error.description}
                        />
                      </div>
                    ) : null}
                    {status === "refused" || status === "validated" ? (
                      <Button
                        className={`btn mt-6 block`}
                        isSubmitting={isSubmitting}
                        isForm={true}
                        textLabel="Archiver"
                        type="submit"
                      />
                    ) : null}
                  </Form>
                );
              }}
            </Formik>
          ) : null}
          <PermissionChecker
            permissions={[
              "kdix.actions.absence.edit",
              "kdix.actions.absence.edit.agency",
              "kdix.actions.absence.edit.department",
              "kdix.actions.absence.edit.service",
              "kdix.actions.absence.edit.own",
            ]}
          >
            {canEdit ? (
              <div className={"flex"}>
                <Link
                  className="btn mt-6 text-center"
                  to={`/validation-absences`}
                >
                  Accéder au formulaire de validation
                </Link>
              </div>
            ) : null}
          </PermissionChecker>
        </div>
      </div>
    </Block>
  );
}

function AbsenceNotificationsList() {
  const userId = useUserId();
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({ viewed: [false] });
  const filtersList = [
    {
      label: "État",
      key: "viewed",
      options: [
        {
          label: "Non archivé",
          value: false,
          id: false,
        },
        {
          label: "Archivé",
          value: true,
          id: true,
        },
      ],
    },
  ];
  const { data: absenceNotifications } = useGetUserAbsenceNotifications(
    userId,
    { ...filters }
  );

  //On filtre les notifications pour ne pas afficher de doublons, et pour correspondre au #962
  //Rustine en attendant un vrai rework des notifs d'absence
  let filteredAbsenceNotifications = [];
  let filteredAbsenceNotificationsIndexByAbsenceId = [];

  for (const absenceNotification of absenceNotifications) {
    if (absenceNotification.message === "absence_notification.absence_is_close") {
      filteredAbsenceNotifications.push(absenceNotification);
      filteredAbsenceNotificationsIndexByAbsenceId.push("-1");
      continue ;
    }
    if (!filteredAbsenceNotificationsIndexByAbsenceId.includes(absenceNotification?.absence.id)) {
      filteredAbsenceNotifications.push(absenceNotification);
      filteredAbsenceNotificationsIndexByAbsenceId.push(absenceNotification?.absence.id)
    } else {
      if (absenceNotification.message === "absence_notification.delay_validation_has_passed") {
        filteredAbsenceNotifications.splice(filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id), 1, absenceNotification);
      } else {
        if (absenceNotification.message === "absence_notification.need_documents") {
          switch (filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message) {
          case "absence_notification.need_prevalidation":
            filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message = "absence_notification.need_prevalidation.need_documents";
            break;
          case "absence_notification.need_validation":
            filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message = "absence_notification.need_validation.need_documents";
            break;
          case "absence_notification.prevalidated":
            filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message = "absence_notification.prevalidated.need_documents";
            break;
          case "absence_notification.validated":
            filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message = "absence_notification.validated.need_documents";
            break;
          default :
            break;
        }
      }
      if (filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message === "absence_notification.has_documents") {
        switch (absenceNotification.message) {
          case "absence_notification.need_prevalidation":
            filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message = "absence_notification.need_prevalidation.has_documents";
            break;
          case "absence_notification.need_validation":
            filteredAbsenceNotifications[filteredAbsenceNotificationsIndexByAbsenceId.indexOf(absenceNotification?.absence.id)].message = "absence_notification.need_validation.has_documents";
            break;
          default :
            break;
        }
      }
      }
    }
  }

  return (
    <div>
      <div className="px-8 mt-8 mb-4 flex">
        <button
          className="flex items-center"
          onClick={() => toggleFilters(true)}
        >
          <FilterIcon />
          <span className="ml-4">Filtres</span>
        </button>
        <Modal
          title="Filtres"
          handleClose={(e) => {
            e.stopPropagation();
            toggleFilters(false);
          }}
          isOpen={filtersOpen}
          onRequestClose={() => toggleFilters(false)}
        >
          <div className="-mx-4 px-5">
            {map(filtersList, ({ key, label, options }) => {
              return (
                <CheckboxGroup
                  key={key}
                  label={label}
                  options={options}
                  className="py-4 odd:bg-purple-50 -mx-4 px-5"
                  selected={get(filters, key, [])}
                  onAdd={(option) => {
                    setFilter(key, [...get(filters, key, []), option]);
                  }}
                  onRemove={(option) => {
                    const newFilters = pull(get(filters, key, []), option);
                    if (size(newFilters) === 0) {
                      removeFilter(key);
                      return;
                    }
                    setFilter(key, newFilters);
                  }}
                />
              );
            })}
          </div>
          <div>
            <div
              className="mt-3 underline cursor-pointer"
              onClick={() => {
                resetFilters();
              }}
            >
              Supprimer les filtres
            </div>
          </div>
          <button
            className="btn mt-5 w-full"
            type="button"
            onClick={() => toggleFilters(false)}
          >
            Appliquer les filtres
          </button>
        </Modal>
      </div>
      {map(filteredAbsenceNotifications, (notification) => {
        return (
          <AbsenceNotificationsItem
            key={notification.id}
            notification={notification}
          />
        );
      })}
      {size(filteredAbsenceNotifications) <= 0 ? (
        <div className="mt-12">
          <Alert type="warning" message="aucune notification" />
        </div>
      ) : null}
    </div>
  );
}
export default AbsenceNotificationsList;
