import React, { useEffect, useState } from "react";
import { useCheckMood, useSendMood } from "../../api";
import { useQueryMoodTypes } from "../../api/moodType";

import Block from "../Block";
import { ReactComponent as RainIcon } from "../../svgs/rain.svg";
import { ReactComponent as StormIcon } from "../../svgs/storm.svg";
import { ReactComponent as SunCloudIcon } from "../../svgs/sun-cloud.svg";
import { ReactComponent as SunIcon } from "../../svgs/sun.svg";
import { map } from "lodash-es";
import { useEffectOnce } from "react-use";
import { useIsImpersonate, useUserId } from "../../contexts/permissions";

function Choice({ Icon, selected = false, onClick }) {
  return (
    <div
      className={`${
        selected ? "bg-purple-100" : ""
      } flex items-center justify-center w-16 h-16 cursor-pointer`}
      onClick={onClick}
    >
      <Icon className="w-10 h-10 text-purple-500 fill-current mx-auto" />
    </div>
  );
}

const typeIconMap = {
  Soleil: SunIcon,
  Nuage: SunCloudIcon,
  Pluie: RainIcon,
  Orage: StormIcon,
};

function Mood({ onSubmit }) {
  const [selected, setSelected] = useState(null);
  const impersonate = useIsImpersonate();
  const userId = useUserId();
  // const { data: moods, loading, error } = useGetMoods();
  const { data: moods } = useQueryMoodTypes();

  const [needMood, checkMood] = useCheckMood(userId);
  const [state, sendMood] = useSendMood();

  useEffectOnce(() => {
    if (!impersonate) {
      checkMood();
    }
  });

  useEffect(() => {
    function post() {
      sendMood({
        data: {
          type: selected,
        },
      });
      onSubmit();
    }

    if (selected && moods.length > 0) {
      post();
    }
  }, [selected, sendMood, onSubmit, state, moods]);

  if (!needMood.value || moods.length === 0) return null;

  return (
    <div className="fixed h-full w-full inset-0 z-50">
      <div className="fixed bg-black h-full w-full inset-0 z-40 opacity-50"></div>
      <div
        className="absolute top-0 left-0 z-50 p-4"
        style={{
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          maxWidth: "315px",
          width: "100%",
        }}
      >
        <Block>
          <div className="text-xl font-bold text-center mb-4 leading-tight">
            Comment vous sentez-vous aujourd’hui ?
          </div>

          <div className="flex justify-between">
            {map(moods, (mood) => (
              <Choice
                key={mood.id}
                Icon={typeIconMap[mood.label]}
                selected={selected === mood["@id"]}
                onClick={() => {
                  setSelected(mood["@id"]);
                }}
              />
            ))}
          </div>
        </Block>
      </div>
    </div>
  );
}

export default Mood;
