import CollaboratorProvider, {
  CollaboratorContext,
} from "../../contexts/collaborator";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { get, map, endsWith } from "lodash-es";

import Activite from "./Activite";
import Alert from "../../components/Alert";
import { ReactComponent as ArrowRightIcon } from "../../svgs/arrow-right.svg";
import Contrat from "./Contrat";
import Documents from "./Documents";
import DocumentsConfidentiels from "./DocumentsConfidentiels";
import ErrorBoundary from "../../components/ErrorBoundary";
import General from "./General";
import Header from "./Header";
import { Helmet } from "react-helmet";
import Historique from "./Historique";
import { PermissionChecker } from "../../contexts/permissions";
import Personnel from "./Personnel";
import Complement from "./Complement";
import React from "react";
import Spinner from "../../components/Spinner";
import Messages from "./Messages";
import { useDesktop } from "../../hooks/useDesktop";
import Evaluation from "./Evaluation";

function PageCollaboratorHeader() {
  const { collaborator, currentContract } =
    React.useContext(CollaboratorContext);

  let title = "Chargement du collaborateur...";

  if (get(collaborator, "data.id")) {
    title = `${collaborator.data.firstname} ${collaborator.data.lastname}`;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="sticky top-topHeader z-10">
        <Header
          collaborator={collaborator}
          collaboratorId={get(collaborator, "data.id")}
          userId={get(collaborator, "data.linkedUser.id")}
          firstname={get(collaborator, "data.firstname")}
          lastname={get(collaborator, "data.lastname")}
          job={get(currentContract, "data.job.label")}
          avatar={get(collaborator, "data.avatar.contentUrl")}
          avatarInfos={get(collaborator, "data.avatar")}
          active={get(collaborator, "data.active")}
        />
        <div className="px-8">
          <NavBar />
        </div>
      </div>
    </>
  );
}

function NavBarItem({ to, label, permissions, userId, user = false, isDesktop = true }) {
    
  if (user && !userId) return null;
  if (!isDesktop) return null;
  return (
    <PermissionChecker permissions={permissions}>
        
      <NavLink
        to={to}
        className="inline-block focus:outline-none border-b-4 border-transparent px-4 py-2 whitespace-no-wrap"
        activeClassName="border-purple-600 font-bold"
      >
        {label}
      </NavLink>
    </PermissionChecker>
  );
}

function NavBar() {
  let { url } = useRouteMatch();
  const { collaborator } = React.useContext(CollaboratorContext);
  const userId = get(collaborator, "data.linkedUser.id");
  const agencies = map(get(collaborator, "data.agencies", []), "@id");
  const departments = map(get(collaborator, "data.departments", []), "@id");
  const service = get(collaborator, "service.id", null);
  const showArrows = true;
  const isDesktop = useDesktop();
  const config = [
    {
      to: `${url}/general`,
      label: "Général",
      permissions: [
        "kdix.actions.collaborator.view",
        "kdix.actions.collaborator.view.agency",
        "kdix.actions.collaborator.view.department",
        "kdix.actions.collaborator.view.service",
        "kdix.actions.collaborator.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/complement`,
      label: "Complément",
      permissions: [
        "kdix.actions.additional_information.view",
        "kdix.actions.additional_information.view.agency",
        "kdix.actions.additional_information.view.department",
        "kdix.actions.additional_information.view.service",
        "kdix.actions.additional_information.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/personnel`,
      label: "Personnel",
      permissions: [
        "kdix.actions.personal_information.view",
        "kdix.actions.personal_information.view.agency",
        "kdix.actions.personal_information.view.department",
        "kdix.actions.personal_information.view.service",
        "kdix.actions.personal_information.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/contrat`,
      label: "Contrat",
      permissions: [
        "kdix.actions.personal_information.view",
        "kdix.actions.personal_information.view.agency",
        "kdix.actions.personal_information.view.department",
        "kdix.actions.personal_information.view.service",
        "kdix.actions.personal_information.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/historique`,
      label: "Historique",
      permissions: [
        "kdix.actions.collaborator.history.view",
        "kdix.actions.collaborator.history.view.agency",
        "kdix.actions.collaborator.history.view.department",
        "kdix.actions.collaborator.history.view.service",
        "kdix.actions.collaborator.history.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/documents`,
      label: "Documents",
      permissions: [
        "kdix.actions.general_document.view",
        "kdix.actions.general_document.view.agency",
        "kdix.actions.general_document.view.department",
        "kdix.actions.general_document.view.service",
        "kdix.actions.general_document.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/messages`,
      label: "Messages",
      user: true,
      permissions: [
        "kdix.actions.message.view",
        "kdix.actions.message.view.agency",
        "kdix.actions.message.view.department",
        "kdix.actions.message.view.service",
        "kdix.actions.message.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
    {
      to: `${url}/activite`,
      label: "Mon relevé d'activité",
      permissions: [
        "kdix.actions.collaborator.activity.view",
        "kdix.actions.collaborator.activity.view.agency",
        "kdix.actions.collaborator.activity.view.department",
        "kdix.actions.collaborator.activity.view.service",
        "kdix.actions.collaborator.activity.view.own",
      ],
      userId,
      agencies,
      departments,
      service,
    },
    {
      to: `${url}/evaluations`,
      label: "Évaluations",
      user: false,
      isDesktop: isDesktop? true : false,
      permissions: [
        "kdix.actions.skill_evaluation.view_evaluations",
        "kdix.actions.skill_evaluation.view_evaluations.agency",
        "kdix.actions.skill_evaluation.view_evaluations.department",
        "kdix.actions.skill_evaluation.view_evaluations.service",
        "kdix.actions.skill_evaluation.view_evaluations.own",
      ],
      userId,
      agencies,
      departments,
      service,
    },
    {
      to: `${url}/documents-confidentiels`,
      label: "Documents confidentiels",
      permissions: [
        "kdix.actions.confidential_document.view",
        "kdix.actions.confidential_document.view.agency",
        "kdix.actions.confidential_document.view.department",
        "kdix.actions.confidential_document.view.service",
        "kdix.actions.confidential_document.view.own",
      ],
      agencies,
      userId,
      departments,
      service,
    },
  ];
  const navRef = React.useRef(null);

  return (
    <div className="relative mb-6 bg-purple-200 -mx-8 px-8">
      {showArrows ? (
        <ArrowRightIcon className="absolute left-0 top-0 w-2 h-5 fill-current text-purple-700 mt-3 ml-3 transform rotate-180" />
      ) : null}
      <div ref={navRef} className="flex overflow-x-auto">
        {map(config, (item, index) => (
          <NavBarItem key={index} {...item} />
        ))}
      </div>
      {showArrows ? (
        <ArrowRightIcon className="absolute right-0 top-0 w-2 h-5 fill-current text-purple-700 mt-3 mr-3" />
      ) : null}
    </div>
  );
}

function CollaboratorError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération du collaborateur"
        details={get(error, "description")}
      />
    </div>
  );
}

function Collaborator() {
  let { url } = useRouteMatch();
  const { error } = React.useContext(CollaboratorContext);
  const location = useLocation();
  const isDesktop = useDesktop();
  if (error) {
    return <CollaboratorError error={error} />;
  }

  return (
    <div
      className={`pb-24 ${
        endsWith(location.pathname, "messages") && isDesktop
          ? "messagesPage"
          : ""
      }`}
    >
      <PageCollaboratorHeader />
      <div className="px-8">
        <div className="relative">
          <ErrorBoundary fallback="Erreur lors de l'accès au collaborateur">
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                <Route path={`${url}/general`}>
                  <General />
                </Route>

                <Route path={`${url}/contrat`}>
                  <Contrat url={url} />
                </Route>

                <Route path={`${url}/personnel`}>
                  <Personnel />
                </Route>

                <Route path={`${url}/historique`}>
                  <Historique />
                </Route>

                <Route path={`${url}/activite`}>
                  <Activite url={url} />
                </Route>

                <Route path={`${url}/documents`}>
                  <Documents />
                </Route>

                <Route path={`${url}/documents-confidentiels`}>
                  <DocumentsConfidentiels />
                </Route>

                <Route path={`${url}/messages`}>
                  <Messages />
                </Route>

                <Route path={`${url}/complement`}>
                  <Complement />
                </Route>

                <Route path={`${url}/evaluations`}>
                  <Evaluation />
                </Route>

                <Route path={`${url}`}>
                  <Redirect to={`${url}/general`} />
                </Route>
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

function CollaboratorPage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/rh/collaborateurs" />;
  }
  return (
    <ErrorBoundary fallback="Récupération du collaborateur impossible">
      <CollaboratorProvider id={id}>
        <Collaborator />
      </CollaboratorProvider>
    </ErrorBoundary>
  );
}

export default CollaboratorPage;
